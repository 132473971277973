export default {

	data: function() {

		return {
			is: {
				moving: false
			},
			searchText: '',
			order: [],
			selected: []
		}

	},
	
	created: function() {

		this.$_.each(this.list, function(item) {

			this.order.push(item.id)

		}.bind(this))

	},

	computed: {

		list: function() {

			var list

			if (this.searchText) {

				list = this.$_.filter((this.store) ? this.$store.getters[this.store] : this.generatedList, this.search.bind(this))

			} else {

				list = (this.store) ? this.$store.getters[this.store] : this.generatedList

			}

			list = (this.filter) ? this.$_.filter(list, this.filter.bind(this)) : list

			return (this.sort) ? this.$_.sortBy(list, this.sort) : list

		}

	},

	methods: {

		isSelected: function(id) {
		
			return this.$_.contains(this.selected, id)

		},

		onSelectClick: function(id) {

			if(this.isSelected(id)) {
			
				this.selected.splice(this.selected.indexOf(id), 1)
			
			} else {
			
				this.selected.push(id)
			
			}

		},

		onDeleteClick: function() {

			this.$router.push({
				name: this.$route.name + '.Delete',
				query: {
					items: this.selected.join(',')
				}
			})

		},

		onReordered: async function(order) {

			this.is.moving = true

			await this.$store.dispatch(this.store + '/reorder', order)

			this.is.moving = false

		}	

	}

}